import bowser from "bowser";

export const WEB_STORAGE_MODULE_KEY = "webStorage";
export const SECURITY_QUESTIONS_MODULE_KEY = "securityQuestions";
export const SHARE_SERIALIZATION_MODULE_KEY = "shareSerialization";
export const CHROME_EXTENSION_STORAGE_MODULE_KEY = "chromeExtensionStorage";
export const PASSWORD_QUESTION = "what is your password?";
export const SOCIAL_QUESTION = "social_factor";

export const TKEY_REQUIRE_MORE_INPUT = "requires more input";

export const GOOGLE = "google";
export const FACEBOOK = "facebook";
export const REDDIT = "reddit";
export const DISCORD = "discord";
export const TWITCH = "twitch";
export const APPLE = "apple";
export const LINE = "line";
export const GITHUB = "github";
export const LINKEDIN = "linkedin";
export const TWITTER = "twitter";
export const WEIBO = "weibo";
export const JWT = "jwt";
export const PASSKEYS = "passkeys";

export const GOOGLE_LOGIN_PROVIDER = "google";
export const FACEBOOK_LOGIN_PROVIDER = "facebook";
export const REDDIT_LOGIN_PROVIDER = "reddit";
export const DISCORD_LOGIN_PROVIDER = "discord";
export const TWITCH_LOGIN_PROVIDER = "twitch";
export const APPLE_LOGIN_PROVIDER = "apple";
export const LINE_LOGIN_PROVIDER = "line";
export const GITHUB_LOGIN_PROVIDER = "github";
export const KAKAO_LOGIN_PROVIDER = "kakao";
export const LINKEDIN_LOGIN_PROVIDER = "linkedin";
export const TWITTER_LOGIN_PROVIDER = "twitter";
export const WEIBO_LOGIN_PROVIDER = "weibo";
export const WECHAT_LOGIN_PROVIDER = "wechat";
export const EMAIL_PASSWORDLESS_LOGIN_PROVIDER = "email_passwordless";
export const FARCASTER_LOGIN_PROVIDER = "farcaster";
export const AUTHENTICATOR_LOGIN_PROVIDER = "authenticator";
export const SMS_PASSWORDLESS_LOGIN_PROVIDER = "sms_passwordless";
export const PASSKEYS_LOGIN_PROVIDER = "passkeys";

export const ERROR_MISSING_PARAMS = "Missing params";
export const OPENLOGIN_DAPP_MODULE_KEY = "openlogin";

export const LOCAL_STORAGE_KEY = "localStorage";
export const SESSION_STORAGE_KEY = "sessionStorage";
export type STORAGE_TYPE = typeof LOCAL_STORAGE_KEY | typeof SESSION_STORAGE_KEY;

export const USER_MODULE_KEY = "userModule";
export const LOGIN_PERF_MODULE_KEY = "loginPerfModule";
export const TKEY_MODULE_KEY = "tKeyModule";
export const DAPP_MODULES_STORE_KEY = "dappModulesStore";
export const STORE_MODULES = [USER_MODULE_KEY, LOGIN_PERF_MODULE_KEY, TKEY_MODULE_KEY, DAPP_MODULES_STORE_KEY];

export const SOCIAL_BACKUP_MODULE_KEY = "socialBackupModule";
export const PASSKEYS_MODULE_KEY = "passkeysModule";
export const AUTHENTICATOR_MODULE_KEY = "authenticatorModule";

export const SOCIAL_LOGIN_SOURCE = {
  TKEY_INPUT: "tkey-input",
  REGISTER: "register",
  DAPP: "dapp",
} as const;

export const PASSKEYS_ALLOWED_MAP = [bowser.OS_MAP.iOS, bowser.OS_MAP.MacOS, bowser.OS_MAP.Android, bowser.OS_MAP.Windows];

export type SOCIAL_LOGIN_SOURCE_TYPE = (typeof SOCIAL_LOGIN_SOURCE)[keyof typeof SOCIAL_LOGIN_SOURCE];

export const SOCIAL_LOGIN_ACTION = {
  UPGRADING: "upgrading",
  ADD_FACTOR: "add-factor",
  CHANGE_FACTOR: "change-factor",
  VERIFY_FACTOR: "verify-social-factor",
} as const;

export type SOCIAL_LOGIN_ACTION_TYPE = (typeof SOCIAL_LOGIN_ACTION)[keyof typeof SOCIAL_LOGIN_ACTION];

export const SOCIAL_LOGIN_EXTRA = {
  POPUP: "popup",
} as const;

export type SOCIAL_LOGIN_EXTRA_TYPE = (typeof SOCIAL_LOGIN_EXTRA)[keyof typeof SOCIAL_LOGIN_EXTRA];

export const TOAST_TYPES = {
  SUCCESS: "success",
  ERROR: "danger",
  INFO: "info",
};

export const EMAIL_FLOW = {
  link: "link",
  code: "code",
};

export const AUTH_OP = "@auth";
export const USER_REGISTERATION_OP = "@user_registeration";
export const TKEY_LOOKUP_OP = "@tkey_lookup";
export const CHECK_IF_TKEY_EXIST = "@check_if_tkey_exist";
export const TKEY_LOGIN_OP = "@tkey_login";
export const PREFERENCES_OP = "@set_preferences";
export const GET_WALLET_KEY = "@get_wallet_key";
export const REHYDRATE_LOGIN_CONFIG = "@rehydrate_login_config";
export const REHYDRATE_SESSION_CONFIG = "@rehydrate_session_config";
export const VALIDATE_FEATURE_ACCESS = "@validate_feature_access";
export const RETRIEVE_SHARES = "@torus_retrieve_shares";
export const VALIDATE_WHITELIST = "@validate_whitelist";

export const REGISTER_MFA_OP = "@registerv2/mfaOps";
export const REGISTER_RECOVERY_EMAIL_OP = "@register/registerRecoveryEmail";
export const REGISTER_ACCOUNT_PASSWORD_OP = "@register/registerAccountPassword";
export const START_PAGE_OP = "@start";
export const TKEY_CONFIRM_BACKUP_OP = "@tkeyInput/confirmBackUpPhrase";
export const TKEY_ADD_PASSWORD_OP = "@tkeyInput/addPassword";

export const AUTH_FACTOR = {
  SHARE_TRANSFER: "share-transfer",
  BACKUP_PHRASE: "backup-phrase",
  PASSWORD: "password",
  DEVICE: "device",
  SOCIAL: "social",
  AUTHENTICATOR: "authenticator",
  PASSKEYS: "passkeys",
} as const;

export type AUTH_FACTOR_TYPE = (typeof AUTH_FACTOR)[keyof typeof AUTH_FACTOR];

export const DISCORD_ACCESS_DENIED_ERROR = "The+resource+owner+or+authorization+server+denied+the+request";
export const AUTH0_ACCESS_DENIED_ERROR = "The+resource+owner+denied+the+request";
export const REDDIT_ACCESS_DENIED_ERROR = "Forbidden";
export const GITHUB_ACCESS_DENIED_ERROR = "The%20user%20has%20denied%20your%20application%20access";
export const DENIAL_ERROR = "User has denied authorization";
export const FACEBOOK_ACCESS_DENIED_ERROR = "access_denied";
export const AUTH0_AUTHORIZATION_DENIED_ERROR = "Access%20Denied";
export const LOGIN_FLOW = {
  LOGIN: "login",
  REGISTER: "register",
} as const;

export const DEFAULT_LOGO_LIGHT = "https://images.web3auth.io/web3auth-logo-w-light.svg";
export const DEFAULT_LOGO_DARK = "https://images.web3auth.io/web3auth-logo-w.svg";

export const EXTERNAL_AUTH_TOKEN = {
  WEB3AUTH_APP: "web3auth_app_key",
  THRESHOLD: "web3auth_threshold_key",
  WALLET: "web3auth_wallet_key",
} as const;

// Passwordless backend service
export const PASSWORDLESS_SERVER_API_URL = "https://api.web3auth.io/passwordless-service";
export const PASSWORDLESS_SERVER_SOCKET_URL = "https://api-passwordless.web3auth.io";
export const DEVELOP_PASSWORDLESS_SERVER_API_URL = "https://api-develop.web3auth.io/passwordless-service";
export const DEVELOP_PASSWORDLESS_SERVER_SOCKET_URL = "https://api-develop-passwordless.web3auth.io";

// Auth backend service
export const AUTH_SERVER_URL = "https://api.web3auth.io/auth-service";
export const DEVELOP_AUTH_SERVER_URL = "https://api-develop.web3auth.io/auth-service";
